import {ValidationError} from '@eon.cz/apollo13-frontend-common/lib/utils/CommonUtils';
import {Dispatch, PayloadAction, UnknownAction} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {BackendEndpoints} from '../../../../server/BackendEndpoints';
import {RegistrationMunicipalitaModel, RegistrationZakaznikZadatelModel} from '../model/RegistrationModel';
import {
    setCloseRegistrationDialog,
    setOpenRegistrationDialog,
    setRegister,
    setRegisterClearError,
    setRegisterError,
    setRegisterReset,
    setRegisterSuccess,
    setRegisterSuccessInfo,
    setRegistermunicipalitaSuccess,
    setRegistrationData,
    setVerifyMunicipalita,
    setVerifyRegisterError,
    setVerifyRegisterSuccess,
} from '../reducers';

export type RegistrationAction = {
    registrationBack: () => void;
    openRegistrationDialog: (zadatel?: boolean) => void;
    verifyError: (payload: boolean) => PayloadAction<boolean>;
    closeRegistrationDialog: () => UnknownAction;
    closeInfoDialog: () => UnknownAction;
    resetRegistrError: () => void;
    setRegisterErrorMessage: (payload: string | undefined) => void;

    register: (values: Partial<RegistrationZakaznikZadatelModel>, isMobile: boolean | undefined) => void;
    registerMunicipalita: (values: Partial<RegistrationMunicipalitaModel>, isMobile: boolean | undefined) => void;
    registerMunicipalitaFinish: (result: boolean) => void;
    resetRegistr: () => void;
    verifyMunicipalita: (values: Partial<RegistrationMunicipalitaModel>) => void;
};

export const useRegistrationDispatch = (): RegistrationAction => {
    const dispatch: Dispatch<PayloadAction<RegistrationZakaznikZadatelModel | RegistrationMunicipalitaModel> | UnknownAction> = useDispatch();
    return {
        register: (values, isMobile) => {
            dispatch(setRegister());
            fetch(`/api/${BackendEndpoints.REGISTER}`, {
                method: 'POST',
                body: JSON.stringify({...values, isMobile}),
                headers: {'content-type': 'application/json'},
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        if (!res.error.errors || !Array.isArray(res.error.errors) || res.error.errors.length === 0) {
                            dispatch(setRegisterError(res.error));
                            return;
                        }
                        dispatch(setRegisterError(res.error.errors.reduce((_: ValidationError, {message}: {message: string}) => message, '')));
                        return;
                    }
                    dispatch(setRegisterSuccess());
                })
                .catch(() => {
                    dispatch(setRegisterError('Registrace se nezdařila. Zkuste to prosím později.'));
                });
        },

        registerMunicipalita: (values, isMobile) => {
            dispatch(setRegister());
            fetch(`/api/${BackendEndpoints.REGISTER_MUNICIPALITA}`, {
                method: 'POST',
                body: JSON.stringify({...values, isMobile}),
                headers: {'content-type': 'application/json'},
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        if (!res.error.errors || !Array.isArray(res.error.errors) || res.error.errors.length === 0) {
                            throw new Error();
                        }
                        dispatch(setRegisterError(res.error.errors.reduce((_: ValidationError, {message}: {message: string}) => message, '')));
                        return;
                    }

                    dispatch(setRegistermunicipalitaSuccess(true));
                })
                .catch(() => {
                    dispatch(setRegistermunicipalitaSuccess(true));
                });
        },
        registerMunicipalitaFinish: (result: boolean) => {
            dispatch(setRegistermunicipalitaSuccess(result));
        },
        resetRegistr: () => {
            dispatch(setRegisterReset());
        },
        resetRegistrError: () => {
            dispatch(setRegisterError());
        },
        setRegisterErrorMessage: (payload) => {
            dispatch(setRegisterError(payload));
        },

        verifyMunicipalita: (values: Partial<RegistrationMunicipalitaModel>) => {
            dispatch(setVerifyMunicipalita(true));
            fetch(`/api/${BackendEndpoints.VERIFY_MUNICIPALITA}`, {
                method: 'POST',
                body: JSON.stringify({...values}),
                headers: {'content-type': 'application/json'},
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.error) {
                        if (!res.error.errors || !Array.isArray(res.error.errors) || res.error.errors.length === 0) {
                            throw new Error();
                        }
                        dispatch(
                            setRegisterError(
                                res.error.errors.map(({message, code}: {message: string; code: string}) => {
                                    if (code === 'E69') {
                                        return code;
                                    } else {
                                        return message;
                                    }
                                }),
                            ),
                        );
                        dispatch(
                            setRegisterError(
                                res.error.errors.reduce((_: ValidationError, {code, message}: {message: string; code: string}) => {
                                    if (code === 'E69') {
                                        return code;
                                    } else {
                                        return message;
                                    }
                                }, ''),
                            ),
                        );
                    } else {
                        dispatch(setRegisterSuccess());
                        dispatch(setVerifyMunicipalita(false));
                    }

                    dispatch(setVerifyMunicipalita(false));
                    return;
                })
                .catch(() => {
                    dispatch(setVerifyRegisterSuccess());
                    dispatch(setRegisterError());
                });
        },

        verifyError: (payload: boolean) => dispatch(setVerifyRegisterError(payload)),

        registrationBack: () => {
            dispatch(setRegisterClearError());
        },

        openRegistrationDialog: (zadatel?: boolean) => {
            fetch(
                '/api/register/?array=REGISTRACE_ZAKLADNI_OBSAH_HTML&array=REGISTRACE_MUNICIPALITA_OBSAH_HTML&array=REGISTRACE_OBCHODNIK_OBSAH_HTML&array=REGISTRACE_KONCOVY_ZAKAZNIK_OBSAH_HTML&array=REGISTRACE_ZADATEL_OBSAH_HTML&array=REGISTRACE_ZAKLADNI_OBSAH_ELEKTRINA_HTML&array=REGISTRACE_ZAKLADNI_OBSAH_PLYN_HTML',
                {
                    method: 'GET',
                    headers: {'content-type': 'application/json'},
                },
            )
                .then((res) => res.json())
                .then((res) => {
                    dispatch(setRegistrationData(res));
                    dispatch(setOpenRegistrationDialog(zadatel ?? false));
                })
                .catch(() => {
                    dispatch(setRegisterError('nepodařilo se načíst data pro geristraci skupin.'));
                });
        },

        closeRegistrationDialog: (): UnknownAction => dispatch(setCloseRegistrationDialog()),
        closeInfoDialog: (): UnknownAction => dispatch(setRegisterSuccessInfo()),
    };
};
