import {LoadingDialog, NotificationsActionCreator, NotificationsComponent} from '@eon.cz/apollo13-frontend-common';
import {captureException, withScope} from '@sentry/browser';
import {NextPage} from 'next';
import dynamic from 'next/dynamic';
import {ErrorInfo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {Lang} from '../client/Lang';
import {useAuthAction} from '../client/modules/Auth/actions/AuthAction';
import {ErrorFallbackLogin} from '../client/modules/Auth/components/ErrorFallbackLogin';
import {Login} from '../client/modules/Auth/components/Login';
import {LoginDisabled} from '../client/modules/Auth/components/LoginDisabled';
import {getCookieFromClient, getCookieFromServer} from '../client/modules/Auth/service/AuthService';
import {useCommonAction} from '../client/modules/Common/actions/CommonActions';
import {TransitionSlideUp} from '../client/modules/Common/components/transition/TransitionSlideUp';
import {getFromLS, PageService, saveToLS} from '../client/modules/Common/services/PageService';
import {useRegistrationDispatch} from '../client/modules/Registration';
import {Store} from '../client/Store';
import {apolloClient} from '../client/with/withApolloProvider';

const RegistrationDialogContainer = dynamic(() =>
    import('../client/modules/Registration/containers/RegistrationDialogContainer').then((mod) => mod.RegistrationDialogContainer),
);
const CommonNotificationDialog = dynamic(() =>
    import('../client/modules/Common/components/notification/CommonNotificationDialog').then((mod) => mod.CommonNotificationDialog),
);

type Props = {
    error?: string;
};

const LoginPage: NextPage<Props> = ({error}) => {
    const {removeErrorLogin, login} = useAuthAction();
    const {openRegistrationDialog} = useRegistrationDispatch();
    const {setErrorFetchedLogin} = useCommonAction();
    const {closeNotification} = NotificationsActionCreator(useDispatch());
    const {formatMessage} = useIntl();

    const notifications = useSelector((state: Store) => state.notifications.notifications);
    const {isFetchingLogin, isFetchingLoginConfig, loginConfigLoginDisabledHtml} = useSelector((state: Store) => state.auth);
    const errorLogin = useSelector((state: Store) => state.auth.errorLogin);
    const mobile = useSelector((state: Store) => state.common.mobile);
    const errolMobileLogin = getFromLS('mobile').errorMobileLogin;

    if (!isFetchingLogin && !errorLogin && mobile?.isMobile && mobile?.userId && mobile?.autoLogin) {
        login({
            apolloClient,
            signature: mobile?.signature,
            payload: mobile?.payload,
            deviceId: mobile?.deviceId,
            typ: 'DEVICE_SIGNATURE',
            userId: mobile?.userId,
            mobileLogin: true,
        });
    }

    const handleOpenRegistrationDialog = (zadatel?: boolean) => {
        if (!isFetchingLoginConfig) {
            openRegistrationDialog(zadatel);
        }
    };

    const myErrorHandler = (error: Error, errorInfo: ErrorInfo) => {
        withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key as keyof ErrorInfo]);
            });

            captureException(error);
        });
    };

    const handleDeleteError = () => {
        saveToLS('mobile', {errorMobileLogin: undefined});
        setErrorFetchedLogin(undefined);
    };

    const close = () => {
        closeNotification();
        if (mobile?.isMobile) handleDeleteError();
    };

    if (isFetchingLoginConfig || (mobile?.isMobile && isFetchingLogin)) {
        return <LoadingDialog open />;
    }

    return !loginConfigLoginDisabledHtml && error !== 'loginDisabled' ? (
        <ErrorBoundary FallbackComponent={ErrorFallbackLogin} onError={myErrorHandler}>
            <Login onCloseError={removeErrorLogin} openRegistrationDialog={handleOpenRegistrationDialog} disableActions={isFetchingLoginConfig} />
            <LoadingDialog open={isFetchingLogin} TransitionComponent={TransitionSlideUp} description={Lang.UCET_PRIHLASENI_LOADING_DESCRIPTION} />
            <NotificationsComponent notifications={notifications} onClose={close} />
            <RegistrationDialogContainer />
            {!!(mobile?.errorMobileLogin || errolMobileLogin) && mobile?.isMobile && !errorLogin?.message && (
                <CommonNotificationDialog
                    open
                    onClose={handleDeleteError}
                    text={formatMessage({id: mobile?.errorMobileLogin ?? errolMobileLogin})}
                    type="info"
                />
            )}
        </ErrorBoundary>
    ) : (
        <LoginDisabled loginDisabledHtml={loginConfigLoginDisabledHtml} />
    );
};

export default LoginPage;

LoginPage.getInitialProps = async ({req, res, pathname, query}): Promise<any> => {
    const cookie = typeof window !== undefined ? getCookieFromClient() : getCookieFromServer(req);
    if (!!cookie && pathname === '/login' && !query?.reset && !query?.zadatel) {
        PageService.redirect('/', res);
    }

    const error = query?.error ?? null;

    return {
        error,
    };
};
