import {ActionButton} from '@eon.cz/apollo13-frontend-common';
import {RefreshOutlined} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {LandingBackground} from '../../Common/components/layout/LandingBackground';
import {sanitizeHTML} from '../../Common/services/PageService';

type Props = {
    /**
     * HTML with reasons why is login disabled
     */
    loginDisabledHtml: string | undefined | null;
};

/**
 * Komponenta zobrazí,proč je login zakázaný
 */
export const LoginDisabled = ({loginDisabledHtml}: Props) => {
    const {reload} = useRouter();

    return (
        <LandingBackground pageOff>
            <Grid
                container
                sx={{
                    width: 'auto',
                    display: 'flex',
                    margin: 'auto',
                    padding: 1,
                    maxWidth: 800,
                }}
            >
                <Grid size={{xs: 12}}>
                    <Typography
                        variant="h3"
                        sx={{
                            color: 'common.white',
                            textAlign: 'center',
                            marginBottom: 5,
                        }}
                    >
                        <FormattedMessage id={Lang.PORTAL_MIMO_PROVOZ} />
                    </Typography>
                </Grid>
                <Grid size={{xs: 12}}>
                    <Typography
                        variant="h5"
                        sx={{
                            color: 'common.white',
                            textAlign: 'center',
                            marginBottom: 5,
                        }}
                        style={{textAlign: 'center'}}
                        dangerouslySetInnerHTML={{__html: sanitizeHTML(loginDisabledHtml ?? '')}}
                    />
                </Grid>
                <Grid size={{xs: 12}} textAlign="center">
                    <ActionButton variant="contained" caption="profile.reload" icon={<RefreshOutlined />} onClick={reload} />
                </Grid>
            </Grid>
        </LandingBackground>
    );
};
