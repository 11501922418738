import {DialogTitleWithClose} from '@eon.cz/apollo13-frontend-common/lib/components/dialog/DialogTitleWithClose';
import {Dialog, DialogContent, List, ListItem, ListItemText, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {TransitionSlideUp} from '../../Common';

type Props = {
    open: boolean;
    onClose: () => void;
};
export const RegistartionInfoDialog = ({open, onClose}: Props) => {
    return (
        <Dialog open={open} defaultValue="desktop" TransitionComponent={TransitionSlideUp} data-testid={`registrace-info-dialog`} onClose={onClose}>
            <DialogTitleWithClose title="registrace.jsem.registrovany" onClose={onClose} />
            <DialogContent>
                <List sx={{listStyle: 'initial', pl: 2}}>
                    <ListItem sx={{display: 'list-item'}}>
                        <ListItemText
                            primary={
                                <Typography>
                                    <FormattedMessage id="registrace.jsem.registrovany.1" />
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <ListItemText
                            primary={
                                <Typography>
                                    <FormattedMessage id="registrace.jsem.registrovany.2" />
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <ListItemText
                            primary={
                                <Typography>
                                    <FormattedMessage id="registrace.jsem.registrovany.3" />
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <ListItemText
                            primary={
                                <Typography>
                                    <FormattedMessage id="registrace.jsem.registrovany.4" />
                                </Typography>
                            }
                        />
                    </ListItem>
                    <ListItem sx={{display: 'list-item'}}>
                        <ListItemText
                            primary={
                                <Typography>
                                    <FormattedMessage id="registrace.jsem.registrovany.5" />
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>
    );
};
